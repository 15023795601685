// assets
import { IconMessageDots } from '@tabler/icons';
import { IconSocial } from '@tabler/icons';

// constant
const icons = {
    IconMessageDots,
    IconSocial
};

// ==============================|| CONTACT MENU ITEMS ||============================== //

const contact = {
    id: 'contact',
    title: 'Contact',
    type: 'group',
    children: [
        {
            id: 'contact',
            title: 'Contact',
            type: 'item',
            url: '/contact',
            icon: icons.IconMessageDots,
            breadcrumbs: false
        },
        {
            id: 'Socials',
            title: 'Socials',
            type: 'item',
            url: '/utils/socials',
            icon: icons.IconSocial,
            breadcrumbs: false
        }
    ]
};

export default contact;
