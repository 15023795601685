import { useTheme } from '@mui/material/styles';
import logo from 'assets/images/logo.png';

// ==============================|| LOGO PNG ||============================== //

const Logo = () => {
    const theme = useTheme();
    return (
        <img style={theme.palette.mode === 'dark' ? { filter: 'brightness(0) invert(1)' } : {}} src={logo} alt="Excitement" width="150" />
    );
};

export default Logo;
