import dashboard from './dashboard';
import contact from './contact';
import utilities from './utilities';
// ==============================|| MENU ITEMS ||============================== //

const menuItems = {
    items: [dashboard, utilities, contact]
};

export default menuItems;
