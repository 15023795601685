import { createTheme } from '@mui/material/styles';

// assets
import colors from 'assets/scss/_themes-vars.module.scss';

// project imports
import componentStyleOverrides from './compStyleOverride';
import themePalette from './palette';
import themeTypography from './typography';

/**
 * Represent theme style and structure as per Material-UI
 * @param {JsonObject} customization customization parameter object
 */

export const theme = (customization) => {
    const color = colors;
    const currentMode = customization.mode === 'light' ? 'light' : 'dark';

    const themeOption = {
        colors: color,
        heading: currentMode === 'dark' ? color.grey50 : color.grey900,
        paper: currentMode === 'dark' ? color.darkPaper : color.paper,
        backgroundDefault: currentMode === 'dark' ? color.darkPaper : color.paper,
        background: currentMode === 'dark' ? color.darkBackground : color.primaryLight,
        darkTextPrimary: currentMode === 'dark' ? color.darkTextPrimary : color.grey700,
        darkTextSecondary: currentMode === 'dark' ? color.darkTextSecondary : color.grey500,
        textDark: currentMode === 'dark' ? color.grey100 : color.grey900,
        menuSelected: currentMode === 'dark' ? color.darkSecondaryDark : color.secondaryDark,
        menuSelectedBack: currentMode === 'dark' ? color.darkSecondaryLight : color.secondaryLight,
        divider: currentMode === 'dark' ? color.grey50 : color.grey200,
        customization
    };

    const themeOptions = {
        direction: 'ltr',
        palette: themePalette(themeOption),
        mixins: {
            toolbar: {
                minHeight: '48px',
                padding: '16px',
                '@media (min-width: 600px)': {
                    minHeight: '48px'
                }
            }
        },
        typography: themeTypography(themeOption)
    };

    const currentTheme = createTheme({
        ...themeOptions,
        palette: {
            ...themeOptions.palette,
            mode: currentMode
        }
    });
    currentTheme.components = componentStyleOverrides(themeOption);

    return currentTheme;
};

export default theme;
